

.paragraph-title {
    margin-bottom: 23px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph-content {
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 300;
}
.paragraph-content p {
    margin-bottom: 10px;
}

.paragraph-content ul {
    list-style: none;
}
.flex {
    display: flex;
}

@media screen and (min-width: 320px){
    .paragraph-content {
        font-size: 14px;
    }
}

@media screen and (min-width: 600px){
    .paragraph-content {
        font-size: 18px;
    }
}

@media screen and (min-width: 840px){
    .paragraph-content {
        font-size: 20px;
    }
}

@media screen and (min-width: 1200px){
    .paragraph-content {
        font-size: 22px;
    }
}