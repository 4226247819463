:root {
    --laptop-width: 475px;
    --laptop-height: 319px;
}

#laptop1 {
    stroke: #735E1E;
    stroke-opacity: 0.5;
    stroke-width: 2;
}
#laptop2 {
    stroke: #666565;
    stroke-width: 2;
}

.laptop-svg {
    width: 100%;
    height: 100%;
    max-height: var(--laptop-width);
    max-width: var(--laptop-height);
    margin-top: -70px;
    margin-left: auto;
}

@media screen and (max-width: 900px){
    .laptop-svg {
        width: calc(var(--laptop-width)* 0.7);
        height: calc(var(--laptop-height) * 0.7);
        /*transform: scale(.8);*/
        margin-top: -50px;
    }
}

@media screen and (max-width: 640px) {
    .laptop-svg {
        width: calc(var(--laptop-width) * 0.5);
        height: calc(var(--laptop-height) * 0.5);
        /*transform: scale(.8);*/
    }
}