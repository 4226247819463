@import './styles/variables.css';

:root {
    --margin-lg: var(--global-margin-lg);
    --margin-md: var(--global-margin-md);
    --margin-sm: var(--global-margin-sm);
    --logo-height: 121px;
}

.main-page {
    margin: var(--margin-lg);
    font-size: 16px;
}
.site-header {
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: var(--margin-lg);
    z-index: 10;
}
.swipe-container {
    /*margin-top: calc(var(--logo-height) + var(--margin-lg));*/
}
.swipe-container.position-absolute {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
}

.site-footer {
    /*position: absolute;
    bottom: 5px;
    left: calc(50% - 5px);*/

    position: relative;
    right: 0;
/*    bottom: 0;*/
    top: 95vh;
    left: 0;
    padding: 1rem;
    text-align: center;
}

@media screen and (max-width: 900px){
    .main-page {
        font-size: 16px;
        margin: var(--margin-md);
    }
    .logo-svg {
        width: calc(121px * 0.8);
        height: calc(116px * 0.8);
    }
    .menu-svg {
        width: calc(42px * 0.8);
        height: calc(28px * 0.8);
    }
    .arrow-svg {
        width: calc(30px * 0.8);
        height: calc(33px * 0.8);
    }
    .menu-svg #menuLine1,
    .menu-svg #menuLine2,
    .menu-svg #menuLine3 {
        stroke-width: 2px;
    }

}
@media screen and (max-width: 640px){
    .main-page {
        font-size: 16px;
        margin: var(--margin-sm);
    }
    .logo-svg {
        width: calc(121px * 0.5);
        height: calc(116px * 0.5);
    }
    .arrow-svg {
        width: calc(30px * 0.5);
        height: calc(33px * 0.5);
    }
}

/* vertical and horizontal line - to move to separate component*/
.line {
    z-index: -1;
    position: fixed;
}
.vertical-line {
    right: 20%;
    border-left: 1px solid #cfcdcd57;
    height: 100%;
    width: 1px;

    top: 0;
}
.horizontal-line {
    border-bottom: 1px solid #cfcdcd57;
    height: 1px;
    width: 100%;
    bottom: 10%;
    left: 0;
}

/* transition between pages */

.swipe-container {
    /*position: absolute;*/
}

.swipe-enter {
    margin-top: 10%;
    transform: scale(0.8);
}
.swipe-enter-active {
    margin-top: 0;
    transform: scale(1);
    transition: margin-top .5s, transform .5s .5s;
}
.swipe-exit {
    margin-top: 0;
    transform: scale(1);
}
.swipe-exit-active {
    margin-top: -10%;
    transform: scale(0.8);
    transition: transform .5s, margin-top .5s .5s;
}
/* end of for transition between pages */


