.rectangle-svg {
    max-width: 450px;
    max-height: 450px;
    fill: none;
}
.rectangle-svg g {
    opacity: 0.6;
}
.rectangle-svg:hover {
    cursor: pointer;
}
.rectangle-svg:hover g {
    opacity: 1;
}
.rectangle-svg:hover g #rect1,
.rectangle-svg:hover g #rect2,
.rectangle-svg:hover g #rect3 {
    stroke-width: 3;
}
#rect1 {
    stroke: black;
    stroke-width: 2;
}
#rect2 {
    stroke: #666565;
    stroke-width: 2;
}
#rect3 {
    stroke: #dcb540; /*#735E1E;*/
    stroke-width: 3;
}

.rect-svg-text {
    fill: black;
    text-anchor: middle;
}
.project-scifail {
    font-size: 85px;
    font-family: 'Open Sans Condensed';
}
.project-nm {
    font-size: 31px;
    font-family: 'Open Sans Condensed';
    text-transform: uppercase;
}
.project-fg {
    fill: black;
    transform: translate(30%, 50%);
}