.logo-svg {
    --logo-color: #040404;
    width: 121px;
    height: 116px;
    fill: none;
}
#logoText {
    fill: var(--logo-color);
}

#logoLineLeft,
#logoLineRight,
#logoLineBottom,
#logoLineTop {
    stroke: var(--logo-color);
    stroke-width: 2;
}

/* TODO browser support for var */