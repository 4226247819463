.circles {
    max-height: 80vh;
    max-width: 80vw;
    fill: none;
}
.all-circles-group {
    opacity: 0.6;
}

.circle1 {
    stroke: #C3A036;
    stroke-width: 2;
}

.circle2 {
    stroke: #ABA9A6;
    stroke-opacity: 0.9;
    stroke-width: 2;
}

.circle3 {
    stroke: #666565;
    stroke-width: 2;
}

.circle4 {
    stroke: #C3A036;
    stroke-width: 2;
}

.circle5 {
    stroke: #C4C4C4;
    stroke-width: 2;
}

.circle6 {
    stroke: #FFD75F;
    stroke-width: 2;
}

.landing-text-container {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 9px;
}
.landing-text {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 10em;
    text-align: center;
    white-space: nowrap;
    -webkit-transform: translate(0, calc(50vh - 2em));
            transform: translate(0, calc(50vh - 2em));
}

.landing-subtext {
    font-family: "Open Sans Condensed";
    font-weight: 300;
    font-size: 4em;
    text-align: center;
    -webkit-transform: translate(0, calc(50vh - 2em));
            transform: translate(0, calc(50vh - 2em));
}

.circles {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@media screen and (min-width: 320px){
    .landing-text {
        font-size: 3em;
    }
    .landing-subtext{
        font-size: 2em;
    }
}

@media screen and (min-width: 600px){
    .landing-text {
        font-size: 4em;
    }
}

@media screen and (min-width: 840px){
    .landing-text {
        font-size: 7em;
    }
    .landing-subtext{
        font-size: 4em;
    }
}

@media screen and (min-width: 1200px){
    .landing-text {
        font-size: 10em;
        -webkit-transform: translate(0, calc(50vh - 1.5em));
                transform: translate(0, calc(50vh - 1.5em));
    }
}

.page-container {
    display: grid;
    grid-template-columns: [ title ] 10% [ content ] 80% [ side ] 10% [ end ];
}

/* page title */
.page-title {
    position: relative;
}
.page-title div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
            transform:  translateX(-50%) translateY(-50%) rotate(-90deg);

    font-size: 50px;
    text-transform: uppercase;
    font-weight: 300;
    white-space: nowrap;
    letter-spacing: 6px;
}

/* page content */
.page-content {
    margin-left: 30px;
}

@media screen and (max-width: 840px) {
    .page-container {
        grid-template-columns: [ title ] 30px[ content ] calc(100% - 35px) [ side ] 5px [ end ];
    }
    .page-title div {
        position: fixed;
        -webkit-transform: rotate(-90deg) translateY(100%);
                transform: rotate(-90deg) translateY(100%);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        bottom: 10px;
        top: inherit;
        left: 10px;

        font-size: 20px;
    }
    .page-content {
        margin-left: 0;
    }
}
@media screen and (max-height: 400px) {
    .page-title div {
        font-size: 16px;
    }
}




:root {
    --laptop-width: 475px;
    --laptop-height: 319px;
}

#laptop1 {
    stroke: #735E1E;
    stroke-opacity: 0.5;
    stroke-width: 2;
}
#laptop2 {
    stroke: #666565;
    stroke-width: 2;
}

.laptop-svg {
    width: 100%;
    height: 100%;
    max-height: 475px;
    max-height: var(--laptop-width);
    max-width: 319px;
    max-width: var(--laptop-height);
    margin-top: -70px;
    margin-left: auto;
}

@media screen and (max-width: 900px){
    .laptop-svg {
        width: calc(475px* 0.7);
        width: calc(var(--laptop-width)* 0.7);
        height: calc(319px * 0.7);
        height: calc(var(--laptop-height) * 0.7);
        /*transform: scale(.8);*/
        margin-top: -50px;
    }
}

@media screen and (max-width: 640px) {
    .laptop-svg {
        width: calc(475px * 0.5);
        width: calc(var(--laptop-width) * 0.5);
        height: calc(319px * 0.5);
        height: calc(var(--laptop-height) * 0.5);
        /*transform: scale(.8);*/
    }
}


.paragraph-title {
    margin-bottom: 23px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph-content {
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 300;
}
.paragraph-content p {
    margin-bottom: 10px;
}

.paragraph-content ul {
    list-style: none;
}
.flex {
    display: -webkit-flex;
    display: flex;
}

@media screen and (min-width: 320px){
    .paragraph-content {
        font-size: 14px;
    }
}

@media screen and (min-width: 600px){
    .paragraph-content {
        font-size: 18px;
    }
}

@media screen and (min-width: 840px){
    .paragraph-content {
        font-size: 20px;
    }
}

@media screen and (min-width: 1200px){
    .paragraph-content {
        font-size: 22px;
    }
}
.rectangle-svg {
    max-width: 450px;
    max-height: 450px;
    fill: none;
}
.rectangle-svg g {
    opacity: 0.6;
}
.rectangle-svg:hover {
    cursor: pointer;
}
.rectangle-svg:hover g {
    opacity: 1;
}
.rectangle-svg:hover g #rect1,
.rectangle-svg:hover g #rect2,
.rectangle-svg:hover g #rect3 {
    stroke-width: 3;
}
#rect1 {
    stroke: black;
    stroke-width: 2;
}
#rect2 {
    stroke: #666565;
    stroke-width: 2;
}
#rect3 {
    stroke: #dcb540; /*#735E1E;*/
    stroke-width: 3;
}

.rect-svg-text {
    fill: black;
    text-anchor: middle;
}
.project-scifail {
    font-size: 85px;
    font-family: 'Open Sans Condensed';
}
.project-nm {
    font-size: 31px;
    font-family: 'Open Sans Condensed';
    text-transform: uppercase;
}
.project-fg {
    fill: black;
    -webkit-transform: translate(30%, 50%);
            transform: translate(30%, 50%);
}

.honeycomb {
    -webkit-transform: scale(.9);
            transform: scale(.9);
    margin-top: auto;
    margin-bottom: auto;
}
.honeycomb .comb-row {
    text-align: center;
}
.honeycomb .comb-row a {
    text-decoration: none;
}
.honeycomb .comb-row:nth-child(n+2){
    margin-top: -2%;
    margin-left: -50px;
}
.honeycomb .comb-row:nth-child(odd){
    margin-left: 200px;
}

@media screen and (max-width: 1413px){
    .honeycomb svg {
        display: block;
        margin: auto;
        margin-bottom: 50px;
    }
    .honeycomb .comb-row:nth-child(odd){
        margin-left: 0;
    }
    .honeycomb .comb-row:nth-child(n+2){
        margin-top: 0;
        margin-left: 0;
    }
}

:root {
    --global-margin-lg: 30px;
    --global-margin-md: 20px;
    --global-margin-sm: 10px;

    --highlight-color: #735E1E;

    --font-size-lg: 19px;
    --font-size-md: 16px;
    --font-size-sm: 14px;
}
.project-page {
    --max-width: 1600px;
    --max-image-width: 900px;
}

.project-page {
    max-width: var(--max-width);
    margin: auto;
    font-size: var(--font-size-lg);
}
.project-title {
    font-size: 3em;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 6px;
    font-weight: 100;
}
.project-content {
    display: grid;
    grid-template-columns: 50% 50%;
}
.project-content section {
    grid-column: 1;
    padding: 1em 0;
}
.project-subtitle {
    padding: 1em 0;
    text-transform: uppercase;
}

/* project figure */
.project-figure {
    margin: 3em auto 0;
    padding: 0 3em;
    grid-column-start: 2;
    grid-row: 1 / 5;
}
.project-figure figcaption {
    text-align: center;
    margin-top: 10px;
    font-style: italic;
}
.project-image {
    width: 100%;
    margin: auto;
}
.project-image--limited {
    max-width: var(--max-image-width);
    display: block;
}
.project-image--with-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
/* end of project figure */

/* media queries for whole project page */

@media screen and (max-width: 840px){
    .project-page {
        font-size: var(--font-size-md);
    }
    .project-content {
        grid-template-columns: 100%;
    }
    .project-figure {
        grid-column-start: inherit;
        grid-row: inherit;
        padding: 0;
    }
}
@media screen and (max-width: 640px){
    .project-page {
        font-size: var(--font-size-sm);
    }
}

/* go back link */
.go-back-link-container {
    display: table;
    margin: 10px auto;
    border: solid 1px black;
    padding: 21px 0px 22px 1px;
}
.go-back-link {
    border: solid 1px var(--highlight-color);
    padding: 20px 40px;
    position: relative;
    top: -5px;
    left: 5px;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    text-decoration: none;
    color: #000;
}
.go-back-link:hover,
.go-back-link:active {
    top:0;
    left:0;
    font-weight: 500;
}
/* end of go back link */
.envelop-svg {
    width: 75%;
    fill: none;
}

.envelop1 {
    stroke: #939090;
    stroke-opacity: 0.5;
    stroke-width: 3;
}
.envelop2 {
    stroke: #735E1E;
    stroke-width: 3;
}
.envelop3 {
    stroke: #666565;
    stroke-opacity: 0.3;
    stroke-width: 2;

}
.social-link-icon {
    width: 2.5em;
    height: 2.5em;
    margin: 0 1em 1em 0;
}
.social-link-icon path {
    fill: #000000;
}
.social-link-icon:hover path {
    fill: #a2810c;
}
/* form status */
.form-status {
    position: absolute;
    right: 230px;
}
.form-status__message {
    position: relative;
    bottom: 60px;
    padding: 20px;
    display: -webkit-flex;
    display: flex;
}
.form-status__message span,
.form-status__message svg {
    margin: auto 10px auto;
}
    .form-status {
        bottom: 0;
        right: 0;
        position: fixed;
        background: #4a4a4a;
    }
    .form-status__message {
        bottom: 0;
    }
    .form-status__message span,
    .form-status__message svg {
        color: #d1ffba;
        fill: #d1ffba;
    }
    .form-status__message--error svg,
    .form-status__message--error span {
        color: #ffa47f;
        fill: #ffa47f;
    }

.fade-status-enter {
    opacity: 0;
}
.fade-status-enter-active {
    opacity: 1;
    -webkit-transition: opacity 350ms;
    transition: opacity 350ms;
}
.fade-status-exit {
    opacity: 1;
    -webkit-transition: opacity 350ms;
    transition: opacity 350ms;
}
.fade-status-exit-active {
    opacity: 0;
}
.contact-page {
    display: -webkit-flex;
    display: flex;
}
.contact-picture {
    width: 40%;
    text-align: center;
    margin: auto;
}
.contact-form {
    width: 50%;
    max-width: 700px;
    margin-right: auto;
}

/* SOCIAL LINKS */

.social-links {
    margin-top: 40px;
}
.social-links-icons {
    display: inline-block;
}
.social-links-desc {
    font-size: 1.1em;
    display: inline-block;
    vertical-align: top;
    margin: -10px 10px 0 0;
    line-height: 3.5em;
}

/* FORM */
.form-field {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-top: 10px;
}
.form-field label {
    display: inline-block;
    width: 20%;
    text-align: right;
    margin: auto 10px;
}
.form-field input,
.form-field textarea {
    box-sizing: border-box;
    display: inline-block;
    width: 80%;
    min-height: 4em;
    border: solid 1px black;
}
.submit-btn {
    padding: 15px 50px;
    text-transform: uppercase;
    background: none;
    border: solid 3px #735E1E;
    font-family : inherit;
    font-size: 1em;
    margin-left: auto;
}

@media screen and (max-width: 860px) {
    .contact-page {
        -webkit-flex-flow: column;
                flex-flow: column;
    }
    .contact-form,
    .contact-picture {
        width: 100%;
        margin-bottom: 3em;
    }
    .submit-btn {
        width: 100%;
        max-width: 350px;
    }
    .envelop-svg {
        width: 60%;
    }
}
@media screen and (max-width: 450px) {
    .form-field{
        display: block;
    }
    .form-field label,
    .form-field input,
    .form-field textarea {
        width: 100%;
        display: block;
        text-align: left;
    }
    .form-field label {
        margin: 10px 0 0;
    }
}


.logo-svg {
    --logo-color: #040404;
    width: 121px;
    height: 116px;
    fill: none;
}
#logoText {
    fill: var(--logo-color);
}

#logoLineLeft,
#logoLineRight,
#logoLineBottom,
#logoLineTop {
    stroke: var(--logo-color);
    stroke-width: 2;
}

/* TODO browser support for var */
:root {
    --global-margin: var(--global-margin-lg);
    --menu-width: 200px;
    --slide-animation-timeout: 300ms;
}

/* MENU */
.page-menu {
    background-color: #3E3E3E;
    opacity: .95;
    height: 100vh;
    width: 200px;
    width: var(--menu-width);
    position: fixed;
    top:0;
    right: 0;
    display: -webkit-flex;
    display: flex;
}

.page-menu ul {
    /*margin-top: 35%;
    margin-left: auto;
    margin-right: auto;*/
    margin: auto;
    list-style: none;
    color: white;
    text-transform: uppercase;
    width: 100%;
}
.page-menu ul li {
    padding: 15% 0;
}

.nav-menu-item {
    text-align: center;
}
.nav-menu-item a:hover,
.nav-menu-item a.is-active {
    color: #ffe686;
}

.nav-menu-item a {
    color: white;
    text-decoration: none;
}

/* MENU BTN WITH ANIMATIONS */
.menu-btn {
    background: transparent;
    border: none;
    width: 30px;
    height: 24px;
    padding: 0;
    outline: none;
    position: fixed;
    top: var(--global-margin-lg);
    top: var(--global-margin);
    z-index: 10;
}
.menu-btn {
    right: var(--global-margin-lg);
    right: var(--global-margin);
}
.menu--open {
    right: calc(200px + var(--global-margin-lg));
    right: calc(200px + var(--global-margin));
}
.menu-btn::before, .menu-btn::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
}
.menu--open.menu-btn span {
    opacity: 0;
}
.menu-btn span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    overflow: hidden;
    text-indent: 200%;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.menu--open.menu-btn::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
}
.menu-btn::before {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}
.menu--open.menu-btn span {
    opacity: 0;
}

.menu-btn::after {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
}
.menu--open.menu-btn::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
}
.menu-btn::before, .menu-btn::after, .menu-btn span {
    background: #5f656f;
}

/* MENU ANIMATION - slide right */
.slide-menu-enter {
    right: calc(-1 * (200px));
    right: calc(-1 * (var(--menu-width)));
}
.slide-menu-enter-active {
    right: 0;
    -webkit-transition: right 300ms;
    transition: right 300ms;
    -webkit-transition: right var(--slide-animation-timeout);
    transition: right var(--slide-animation-timeout);
}
.slide-menu-exit {
    right: 0;
}
.slide-menu-exit-active {
    right: calc(-1 * (200px));
    right: calc(-1 * (var(--menu-width)));
    -webkit-transition: right 300ms;
    transition: right 300ms;
    -webkit-transition: right var(--slide-animation-timeout);
    transition: right var(--slide-animation-timeout);
}

/* MENU BTN ANIMATIONS- slide left */
.slide-menu-btn-enter {
    rigth: var(--global-margin-lg);
    rigth: var(--global-margin);
}
.slide-menu-btn-enter-active {
    right: calc(200px + var(--global-margin-lg));
    right: calc(var(--menu-width) + var(--global-margin));
    -webkit-transition: right 300ms;
    transition: right 300ms;
    -webkit-transition: right var(--slide-animation-timeout);
    transition: right var(--slide-animation-timeout);
}
/*TODO var(--menu-width) does not work in Chrome!!*/
.slide-menu-btn-exit {
    right: calc(200px + var(--global-margin-lg));
    right: calc(200px + var(--global-margin))
}
.slide-menu-btn-exit-active {
    right: var(--global-margin-lg);
    right: var(--global-margin);
    -webkit-transition: right 300ms;
    transition: right 300ms;
    -webkit-transition: right var(--slide-animation-timeout);
    transition: right var(--slide-animation-timeout);
}
.arrow-svg {
    width: 30px;
    height: 33px;
    -webkit-animation: arrow-move infinite 1.5s linear;
            animation: arrow-move infinite 1.5s linear;
}
.arrow-path {
    /*fill: #6C6C6C;*/
    fill: #505050;
}

@-webkit-keyframes arrow-move {
    0% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
    20% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px)
    }
    40% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
    60% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px)
    }
    80% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
    100% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
}

@keyframes arrow-move {
    0% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
    20% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px)
    }
    40% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
    60% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px)
    }
    80% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
    100% {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px)
    }
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url(/static/media/open-sans-v15-latin-600.7728dd9f.eot); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url(/static/media/open-sans-v15-latin-600.7728dd9f.eot?#iefix) format('embedded-opentype'), 
    url(/static/media/open-sans-v15-latin-600.223a277b.woff2) format('woff2'), 
    url(/static/media/open-sans-v15-latin-600.1cd5320f.woff) format('woff'), 
    url(/static/media/open-sans-v15-latin-600.c062d7d1.ttf) format('truetype'), 
    url(/static/media/open-sans-v15-latin-600.e16f375b.svg#OpenSans) format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300 - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/open-sans-condensed-v12-latin-300.7a1be40e.eot); /* IE9 Compat Modes */
    src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
    url(/static/media/open-sans-condensed-v12-latin-300.7a1be40e.eot?#iefix) format('embedded-opentype'), 
    url(/static/media/open-sans-condensed-v12-latin-300.108f1f23.woff2) format('woff2'), 
    url(/static/media/open-sans-condensed-v12-latin-300.693015cf.woff) format('woff'), 
    url(/static/media/open-sans-condensed-v12-latin-300.cdc8a9f1.ttf) format('truetype'), 
    url(/static/media/open-sans-condensed-v12-latin-300.2995f79b.svg#OpenSansCondensed) format('svg'); /* Legacy iOS */
}

/* raleway-300 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/raleway-v12-latin-300.4f21e6b4.eot); /* IE9 Compat Modes */
    src: local('Raleway Light'), local('Raleway-Light'),
    url(/static/media/raleway-v12-latin-300.4f21e6b4.eot?#iefix) format('embedded-opentype'), 
    url(/static/media/raleway-v12-latin-300.1acb42d7.woff2) format('woff2'), 
    url(/static/media/raleway-v12-latin-300.34f26209.woff) format('woff'), 
    url(/static/media/raleway-v12-latin-300.e7407591.ttf) format('truetype'), 
    url(/static/media/raleway-v12-latin-300.290232af.svg#Raleway) format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url(/static/media/raleway-v12-latin-500.b1739e04.eot); /* IE9 Compat Modes */
    src: local('Raleway Medium'), local('Raleway-Medium'),
    url(/static/media/raleway-v12-latin-500.b1739e04.eot?#iefix) format('embedded-opentype'),  url(/static/media/raleway-v12-latin-500.de818060.woff2) format('woff2'),  url(/static/media/raleway-v12-latin-500.82718281.woff) format('woff'),  url(/static/media/raleway-v12-latin-500.63f872bd.ttf) format('truetype'),  url(/static/media/raleway-v12-latin-500.a8c8ed48.svg#Raleway) format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url(/static/media/raleway-v12-latin-700.9cb23ede.eot); /* IE9 Compat Modes */
    src: local('Raleway Bold'), local('Raleway-Bold'),
    url(/static/media/raleway-v12-latin-700.9cb23ede.eot?#iefix) format('embedded-opentype'), 
    url(/static/media/raleway-v12-latin-700.dcbe8703.woff2) format('woff2'), 
    url(/static/media/raleway-v12-latin-700.5098f8c8.woff) format('woff'), 
    url(/static/media/raleway-v12-latin-700.432d5879.ttf) format('truetype'), 
    url(/static/media/raleway-v12-latin-700.0590c837.svg#Raleway) format('svg'); /* Legacy iOS */
}
/* reset */

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

hr {
    display:block;
    height:1px;
    border:0;
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

/* end of reset */
body {
    font-family: 'Raleway';
    font-weight: 300;
}

strong {
    color: var(--highlight-color);
}
:root {
    --margin-lg: var(--global-margin-lg);
    --margin-md: var(--global-margin-md);
    --margin-sm: var(--global-margin-sm);
    --logo-height: 121px;
}

.main-page {
    margin: var(--global-margin-lg);
    margin: var(--margin-lg);
    font-size: 16px;
}
.site-header {
    position: relative;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-bottom: var(--global-margin-lg);
    margin-bottom: var(--margin-lg);
    z-index: 10;
}
.swipe-container {
    /*margin-top: calc(var(--logo-height) + var(--margin-lg));*/
}
.swipe-container.position-absolute {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
}

.site-footer {
    /*position: absolute;
    bottom: 5px;
    left: calc(50% - 5px);*/

    position: relative;
    right: 0;
/*    bottom: 0;*/
    top: 95vh;
    left: 0;
    padding: 1rem;
    text-align: center;
}

@media screen and (max-width: 900px){
    .main-page {
        font-size: 16px;
        margin: var(--global-margin-md);
        margin: var(--margin-md);
    }
    .logo-svg {
        width: calc(121px * 0.8);
        height: calc(116px * 0.8);
    }
    .menu-svg {
        width: calc(42px * 0.8);
        height: calc(28px * 0.8);
    }
    .arrow-svg {
        width: calc(30px * 0.8);
        height: calc(33px * 0.8);
    }
    .menu-svg #menuLine1,
    .menu-svg #menuLine2,
    .menu-svg #menuLine3 {
        stroke-width: 2px;
    }

}
@media screen and (max-width: 640px){
    .main-page {
        font-size: 16px;
        margin: var(--global-margin-sm);
        margin: var(--margin-sm);
    }
    .logo-svg {
        width: calc(121px * 0.5);
        height: calc(116px * 0.5);
    }
    .arrow-svg {
        width: calc(30px * 0.5);
        height: calc(33px * 0.5);
    }
}

/* vertical and horizontal line - to move to separate component*/
.line {
    z-index: -1;
    position: fixed;
}
.vertical-line {
    right: 20%;
    border-left: 1px solid #cfcdcd57;
    height: 100%;
    width: 1px;

    top: 0;
}
.horizontal-line {
    border-bottom: 1px solid #cfcdcd57;
    height: 1px;
    width: 100%;
    bottom: 10%;
    left: 0;
}

/* transition between pages */

.swipe-container {
    /*position: absolute;*/
}

.swipe-enter {
    margin-top: 10%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
.swipe-enter-active {
    margin-top: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: margin-top .5s, -webkit-transform .5s .5s;
    transition: margin-top .5s, -webkit-transform .5s .5s;
    transition: margin-top .5s, transform .5s .5s;
    transition: margin-top .5s, transform .5s .5s, -webkit-transform .5s .5s;
}
.swipe-exit {
    margin-top: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.swipe-exit-active {
    margin-top: -10%;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: margin-top .5s .5s, -webkit-transform .5s;
    transition: margin-top .5s .5s, -webkit-transform .5s;
    transition: transform .5s, margin-top .5s .5s;
    transition: transform .5s, margin-top .5s .5s, -webkit-transform .5s;
}
/* end of for transition between pages */



