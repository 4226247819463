
.honeycomb {
    transform: scale(.9);
    margin-top: auto;
    margin-bottom: auto;
}
.honeycomb .comb-row {
    text-align: center;
}
.honeycomb .comb-row a {
    text-decoration: none;
}
.honeycomb .comb-row:nth-child(n+2){
    margin-top: -2%;
    margin-left: -50px;
}
.honeycomb .comb-row:nth-child(odd){
    margin-left: 200px;
}

@media screen and (max-width: 1413px){
    .honeycomb svg {
        display: block;
        margin: auto;
        margin-bottom: 50px;
    }
    .honeycomb .comb-row:nth-child(odd){
        margin-left: 0;
    }
    .honeycomb .comb-row:nth-child(n+2){
        margin-top: 0;
        margin-left: 0;
    }
}
