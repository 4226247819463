@import "./../../styles/variables.css";

:root {
    --global-margin: var(--global-margin-lg);
    --menu-width: 200px;
    --slide-animation-timeout: 300ms;
}

/* MENU */
.page-menu {
    background-color: #3E3E3E;
    opacity: .95;
    height: 100vh;
    width: var(--menu-width);
    position: fixed;
    top:0;
    right: 0;
    display: flex;
}

.page-menu ul {
    /*margin-top: 35%;
    margin-left: auto;
    margin-right: auto;*/
    margin: auto;
    list-style: none;
    color: white;
    text-transform: uppercase;
    width: 100%;
}
.page-menu ul li {
    padding: 15% 0;
}

.nav-menu-item {
    text-align: center;
}
.nav-menu-item a:hover,
.nav-menu-item a.is-active {
    color: #ffe686;
}

.nav-menu-item a {
    color: white;
    text-decoration: none;
}

/* MENU BTN WITH ANIMATIONS */
.menu-btn {
    background: transparent;
    border: none;
    width: 30px;
    height: 24px;
    padding: 0;
    outline: none;
    position: fixed;
    top: var(--global-margin);
    z-index: 10;
}
.menu-btn {
    right: var(--global-margin);
}
.menu--open {
    right: calc(200px + var(--global-margin));
}
.menu-btn::before, .menu-btn::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
}
.menu--open.menu-btn span {
    opacity: 0;
}
.menu-btn span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    overflow: hidden;
    text-indent: 200%;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.menu--open.menu-btn::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
}
.menu-btn::before {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}
.menu--open.menu-btn span {
    opacity: 0;
}

.menu-btn::after {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
}
.menu--open.menu-btn::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
}
.menu-btn::before, .menu-btn::after, .menu-btn span {
    background: #5f656f;
}

/* MENU ANIMATION - slide right */
.slide-menu-enter {
    right: calc(-1 * (var(--menu-width)));
}
.slide-menu-enter-active {
    right: 0;
    transition: right var(--slide-animation-timeout);
}
.slide-menu-exit {
    right: 0;
}
.slide-menu-exit-active {
    right: calc(-1 * (var(--menu-width)));
    transition: right var(--slide-animation-timeout);
}

/* MENU BTN ANIMATIONS- slide left */
.slide-menu-btn-enter {
    rigth: var(--global-margin);
}
.slide-menu-btn-enter-active {
    right: calc(var(--menu-width) + var(--global-margin));
    transition: right var(--slide-animation-timeout);
}
/*TODO var(--menu-width) does not work in Chrome!!*/
.slide-menu-btn-exit {
    right: calc(200px + var(--global-margin))
}
.slide-menu-btn-exit-active {
    right: var(--global-margin);
    transition: right var(--slide-animation-timeout);
}