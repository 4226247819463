.social-link-icon {
    width: 2.5em;
    height: 2.5em;
    margin: 0 1em 1em 0;
}
.social-link-icon path {
    fill: #000000;
}
.social-link-icon:hover path {
    fill: #a2810c;
}