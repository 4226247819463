.contact-page {
    display: flex;
}
.contact-picture {
    width: 40%;
    text-align: center;
    margin: auto;
}
.contact-form {
    width: 50%;
    max-width: 700px;
    margin-right: auto;
}

/* SOCIAL LINKS */

.social-links {
    margin-top: 40px;
}
.social-links-icons {
    display: inline-block;
}
.social-links-desc {
    font-size: 1.1em;
    display: inline-block;
    vertical-align: top;
    margin: -10px 10px 0 0;
    line-height: 3.5em;
}

/* FORM */
.form-field {
    width: 100%;
    display: flex;
    margin-top: 10px;
}
.form-field label {
    display: inline-block;
    width: 20%;
    text-align: right;
    margin: auto 10px;
}
.form-field input,
.form-field textarea {
    box-sizing: border-box;
    display: inline-block;
    width: 80%;
    min-height: 4em;
    border: solid 1px black;
}
.submit-btn {
    padding: 15px 50px;
    text-transform: uppercase;
    background: none;
    border: solid 3px #735E1E;
    font-family : inherit;
    font-size: 1em;
    margin-left: auto;
}

@media screen and (max-width: 860px) {
    .contact-page {
        flex-flow: column;
    }
    .contact-form,
    .contact-picture {
        width: 100%;
        margin-bottom: 3em;
    }
    .submit-btn {
        width: 100%;
        max-width: 350px;
    }
    .envelop-svg {
        width: 60%;
    }
}
@media screen and (max-width: 450px) {
    .form-field{
        display: block;
    }
    .form-field label,
    .form-field input,
    .form-field textarea {
        width: 100%;
        display: block;
        text-align: left;
    }
    .form-field label {
        margin: 10px 0 0;
    }
}

