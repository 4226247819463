.envelop-svg {
    width: 75%;
    fill: none;
}

.envelop1 {
    stroke: #939090;
    stroke-opacity: 0.5;
    stroke-width: 3;
}
.envelop2 {
    stroke: #735E1E;
    stroke-width: 3;
}
.envelop3 {
    stroke: #666565;
    stroke-opacity: 0.3;
    stroke-width: 2;

}