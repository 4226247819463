/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300 - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-condensed-v12-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
    url('../fonts/open-sans-condensed-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-condensed-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-condensed-v12-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-condensed-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-condensed-v12-latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}

/* raleway-300 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/raleway-v12-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Raleway Light'), local('Raleway-Light'),
    url('../fonts/raleway-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/raleway-v12-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/raleway-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/raleway-v12-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/raleway-v12-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Raleway Medium'), local('Raleway-Medium'),
    url('../fonts/raleway-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/raleway-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/raleway-v12-latin-500.woff') format('woff'), /* Modern Browsers */ url('../fonts/raleway-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/raleway-v12-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/raleway-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Raleway Bold'), local('Raleway-Bold'),
    url('../fonts/raleway-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/raleway-v12-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/raleway-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/raleway-v12-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}