:root {
    --global-margin-lg: 30px;
    --global-margin-md: 20px;
    --global-margin-sm: 10px;

    --highlight-color: #735E1E;

    --font-size-lg: 19px;
    --font-size-md: 16px;
    --font-size-sm: 14px;
}