@import './../../styles/variables.css';

.project-page {
    --max-width: 1600px;
    --max-image-width: 900px;
}

.project-page {
    max-width: var(--max-width);
    margin: auto;
    font-size: var(--font-size-lg);
}
.project-title {
    font-size: 3em;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 6px;
    font-weight: 100;
}
.project-content {
    display: grid;
    grid-template-columns: 50% 50%;
}
.project-content section {
    grid-column: 1;
    padding: 1em 0;
}
.project-subtitle {
    padding: 1em 0;
    text-transform: uppercase;
}

/* project figure */
.project-figure {
    margin: 3em auto 0;
    padding: 0 3em;
    grid-column-start: 2;
    grid-row: 1 / 5;
}
.project-figure figcaption {
    text-align: center;
    margin-top: 10px;
    font-style: italic;
}
.project-image {
    width: 100%;
    margin: auto;
}
.project-image--limited {
    max-width: var(--max-image-width);
    display: block;
}
.project-image--with-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
/* end of project figure */

/* media queries for whole project page */

@media screen and (max-width: 840px){
    .project-page {
        font-size: var(--font-size-md);
    }
    .project-content {
        grid-template-columns: 100%;
    }
    .project-figure {
        grid-column-start: inherit;
        grid-row: inherit;
        padding: 0;
    }
}
@media screen and (max-width: 640px){
    .project-page {
        font-size: var(--font-size-sm);
    }
}

/* go back link */
.go-back-link-container {
    display: table;
    margin: 10px auto;
    border: solid 1px black;
    padding: 21px 0px 22px 1px;
}
.go-back-link {
    border: solid 1px var(--highlight-color);
    padding: 20px 40px;
    position: relative;
    top: -5px;
    left: 5px;
    transition: all 100ms;
    text-decoration: none;
    color: #000;
}
.go-back-link:hover,
.go-back-link:active {
    top:0;
    left:0;
    font-weight: 500;
}
/* end of go back link */