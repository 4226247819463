.arrow-svg {
    width: 30px;
    height: 33px;
    animation: arrow-move infinite 1.5s linear;
}
.arrow-path {
    /*fill: #6C6C6C;*/
    fill: #505050;
}

@keyframes arrow-move {
    0% {
        transform: translateY(-20px)
    }
    20% {
        transform: translateY(0px)
    }
    40% {
        transform: translateY(-20px)
    }
    60% {
        transform: translateY(0px)
    }
    80% {
        transform: translateY(-20px)
    }
    100% {
        transform: translateY(-20px)
    }
}