.circles {
    max-height: 80vh;
    max-width: 80vw;
    fill: none;
}
.all-circles-group {
    opacity: 0.6;
}

.circle1 {
    stroke: #C3A036;
    stroke-width: 2;
}

.circle2 {
    stroke: #ABA9A6;
    stroke-opacity: 0.9;
    stroke-width: 2;
}

.circle3 {
    stroke: #666565;
    stroke-width: 2;
}

.circle4 {
    stroke: #C3A036;
    stroke-width: 2;
}

.circle5 {
    stroke: #C4C4C4;
    stroke-width: 2;
}

.circle6 {
    stroke: #FFD75F;
    stroke-width: 2;
}