.page-container {
    display: grid;
    grid-template-columns: [ title ] 10% [ content ] 80% [ side ] 10% [ end ];
}

/* page title */
.page-title {
    position: relative;
}
.page-title div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:  translateX(-50%) translateY(-50%) rotate(-90deg);

    font-size: 50px;
    text-transform: uppercase;
    font-weight: 300;
    white-space: nowrap;
    letter-spacing: 6px;
}

/* page content */
.page-content {
    margin-left: 30px;
}

@media screen and (max-width: 840px) {
    .page-container {
        grid-template-columns: [ title ] 30px[ content ] calc(100% - 35px) [ side ] 5px [ end ];
    }
    .page-title div {
        position: fixed;
        transform: rotate(-90deg) translateY(100%);
        transform-origin: 0% 100%;
        bottom: 10px;
        top: inherit;
        left: 10px;

        font-size: 20px;
    }
    .page-content {
        margin-left: 0;
    }
}
@media screen and (max-height: 400px) {
    .page-title div {
        font-size: 16px;
    }
}



