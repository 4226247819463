
.landing-text-container {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 9px;
}
.landing-text {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 10em;
    text-align: center;
    white-space: nowrap;
    transform: translate(0, calc(50vh - 2em));
}

.landing-subtext {
    font-family: "Open Sans Condensed";
    font-weight: 300;
    font-size: 4em;
    text-align: center;
    transform: translate(0, calc(50vh - 2em));
}

.circles {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@media screen and (min-width: 320px){
    .landing-text {
        font-size: 3em;
    }
    .landing-subtext{
        font-size: 2em;
    }
}

@media screen and (min-width: 600px){
    .landing-text {
        font-size: 4em;
    }
}

@media screen and (min-width: 840px){
    .landing-text {
        font-size: 7em;
    }
    .landing-subtext{
        font-size: 4em;
    }
}

@media screen and (min-width: 1200px){
    .landing-text {
        font-size: 10em;
        transform: translate(0, calc(50vh - 1.5em));
    }
}
