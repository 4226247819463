/* form status */
.form-status {
    position: absolute;
    right: 230px;
}
.form-status__message {
    position: relative;
    bottom: 60px;
    padding: 20px;
    display: flex;
}
.form-status__message span,
.form-status__message svg {
    margin: auto 10px auto;
}
    .form-status {
        bottom: 0;
        right: 0;
        position: fixed;
        background: #4a4a4a;
    }
    .form-status__message {
        bottom: 0;
    }
    .form-status__message span,
    .form-status__message svg {
        color: #d1ffba;
        fill: #d1ffba;
    }
    .form-status__message--error svg,
    .form-status__message--error span {
        color: #ffa47f;
        fill: #ffa47f;
    }

.fade-status-enter {
    opacity: 0;
}
.fade-status-enter-active {
    opacity: 1;
    transition: opacity 350ms;
}
.fade-status-exit {
    opacity: 1;
    transition: opacity 350ms;
}
.fade-status-exit-active {
    opacity: 0;
}